import React from 'react'
import './TrioCard.css'
interface Props {
    img: string,
    cityName: string,
    cityIcon: string,
    tags: any,
    text: string,
}
export const TrioCard = ({
    img,
    cityName,
    cityIcon,
    tags,
    text,
}: Props) => {

    return (
        <div className="trioCard" data-aos="fade-up" >
            <div className="trioCardThumbnail">
                <img src={img} alt="Los Santos" />
            </div>
            <div className="trioCardInformations">
                <div className="trioCardHeader">
                    <p>{cityName}</p>
                    <img src={cityIcon} alt="State Logo" />
                </div>
                <div className="trioCardTags">
                    {tags.map((item: any, index: any) => (
                        <span key={index}>{item}</span>
                    ))}
                </div>
                <hr />
                <div className="trioCardText">
                    {text}
                </div>
                <div className="trioCardFooter">
                    Klingt nach dir?<br />
                    <strong>Lass dich noch heute Whitelisten!</strong><br /><br />
                    <a href="https://go.triostate.net/whitelist" aria-label="Triostate Whitelist Link"><button>Zum Whitelisting</button></a>
                </div>
            </div>
        </div >
    )
}