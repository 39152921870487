import SectionSeperator from '../../images/banner3.svg'
import './Header.css'
export const Header = () => {
    return (
        <section className="section header">
            <div className="mainSectionWrapper">
                <div className="mainSectionTextContainer">
                    <span className="subTitle">Ein Projekt, Drei Staaten, Unendliche Möglichkeiten!</span>
                    <span className="mainTitle"><span>Trio</span>State</span>
                </div>
            </div>
            <img src={SectionSeperator} alt="sectiontrenner" />
        </section>
    )
}