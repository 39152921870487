import { Routes, Route, Link, BrowserRouter } from "react-router-dom";
import { SectionLoader } from "../components/views/home/SectionLoader";
import { Impressum } from "../components/views/impressum/Impressum";

export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SectionLoader/>}/>
        <Route path="/impressum" element={<Impressum/>}/>
        <Route path="/datenschutz" element={<Impressum/>}/>
        <Route path="*" element={<NoMatch />}/>
      </Routes>
    </BrowserRouter>
  );
}

function NoMatch() {
  return (
    <div>
      <h2 className="text-black">Nothing to see here!</h2>
      <p className="text-black">
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}