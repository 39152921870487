import React, { ReactNode } from 'react'
import { Footer } from '../../../modules/Footer/Footer';
import './Layout.css'

import HomeIcon from "../../../images/icons/home.svg"
import WhitelistIcon from "../../../images/icons/whitelist.svg"
import RulesIcon from "../../../images/icons/rules.svg"
type Props = {
  children: ReactNode
}
export const Layout = ({ children }: Props) => {
  return (
    <div>
      <nav>
        <ul>
          <li><a href="/"><img src={HomeIcon} alt="Home Link" /><span>Home</span></a></li>
          <li><a href="https://go.triostate.net/whitelist" aria-label="Triostate Whitelist"><img src={WhitelistIcon} alt="" /><span>Whitelist</span></a></li>
          <li><a href="https://forum.triostate.net/thread/6-allgemeine-netzwerkrichtlinien/" aria-label="Triostate Forum"><img src={RulesIcon} alt="" /><span>Regelwerk</span></a></li>
        </ul>
      </nav>
      {children}
      <Footer />
    </div>
  );
}