import './ContentCard.css'

interface ContentObject {
    [key: string]: string;
  }

interface Props {
    img: string,
    title: string,
    reverse: boolean,
    slogan: string,
    content: ContentObject
}
export const ContentCard = ({
    img,
    title,
    reverse,
    slogan,
    content
}: Props) => {
    return (
            <div className={reverse ? 'contentCard reverse' : 'contentCard'}>
            <div className="imgContainer">
                <img src={img} alt="content preview" />
            </div>

            <div className={reverse ? 'contentText contentTextRight' : 'contentText'}>
                <div className="sloganBox">
                    <h2>{title}</h2>
                    <p className="slogan">{slogan}</p>
                </div>
                <div className="content">
                    {Object.entries(content).map(([title, paragraph], index) => (
                        <div key={index}>
                            <h4>{title}</h4>
                            <p>{paragraph}</p>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
}