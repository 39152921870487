import react from "react";
import './Button.css'

interface Props{
    link:string, 
    disabled: boolean, 
    buttonText:string
}

export const Button = ({link,disabled,buttonText}: Props) => {
    return(
    <>
    <a href={link} aria-label={buttonText}>
        <button className="bigButton" disabled={disabled}>
          <span>{buttonText}</span>
        </button>
      </a>
      </>
      )
}

export default Button