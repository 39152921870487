
import BannerTop from '../../../images/bannertop.svg'
import BannerBot from '../../../images/banner.svg'
import LS from '../../../images/LosSantos.jpg'
import SS from '../../../images/BlaineCounty.jpg'
import CP from '../../../images/CayoPerico.jpeg'

import LSCityIcon from '../../../images/Siegel_von_Los_Santos.webp'
import SSCityIcon from '../../../images/Blaine_County-Seal-GTAV.webp'

import './sectionTwo.css'

import { TrioCard } from '../../../modules/TrioCard/TrioCard'

export const SectionTwo = () => {

    const tagsLS = ["Business", "Gangs", "Reichtum", "Metropole"]
    const tagsSS = ["Wüste", "Armut", "Outlaw", "Landwirtschaft"]
    const tagsCP = ["Cartêl", "Korruption", "Waffen", "Drogen", "Gesetzlos"]

    const textLS = "Highlife, Luxus, Wohlstand? Oder doch nur eine verlogene Fassade... Sicheres Leben oder doch nur ein Kampf mit den eigenen Bedürfnissen?"
    const textSS = "Ein Staat für jeden, keine Angst mehr vor deiner nächsten Arztrechnung? Keine Arbeitslosigkeit? Freiheit und keine Lust auf Großstadt? Lebe den Blaine County Traum!"
    const textCP = "Gesetze sind nicht wirklich deins? Statt der Hochmodernen Zelle doch lieber ins Verließ? Eine riesen Kluft zwischen Arm und Reich, der Himmel aber auch die Hölle..."


    return (
        <section>
            <img src={BannerTop} alt="sectiontrenner"/>
                <div className="Section2TextBox">
                    <h3>DREI STAATEN PRINZIP</h3>
                    <h2>
                        Lebe deinen Traum! Egal ob als
                        <span className="highlight"> Geschäftsmann</span>, 
                        <span className="highlight"> Krimineller</span> oder auf dem Land mit
                        deiner <span className="highlight">eigenen kleinen Farm!</span>
                    </h2>
                </div>
                <div className="trioCardWrapper">
                    <TrioCard cityName="Los Santos" tags={tagsLS} img={LS} cityIcon={LSCityIcon} text={textLS} />
                    <TrioCard cityName="Blaine County" tags={tagsSS} img={SS} cityIcon={SSCityIcon} text={textSS} />
                    <TrioCard cityName="Cayo Perico" tags={tagsCP} img={CP} cityIcon={LSCityIcon} text={textCP} />
                </div>
                <img src={BannerBot} alt="sectiontrenner"/>
                </section>

                )
}